import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS } from "@contentful/rich-text-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

export const query = graphql`
  query($ID: String!) {
    allContentfulExhibition(filter: { id: { eq: $ID } }) {
      nodes {
        name
        description {
          json
        }
      }
    }
    allContentfulProject(filter: { id: { eq: $ID } }) {
      nodes {
        name
        description {
          json
        }
      }
    }
  }
`

export default ({ data }) => {
  const options = {
    renderText: text => {
      return text.split("\n").reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
      }, [])
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        let { description, title, file } = node.data.target.fields

        return (
          <div className={"row"}>
            <div className={"eight columns offset-by-two"}>
              <img
                src={file["en-US"].url}
                alt={file["en-US"].fileName}
                style={{ width: "100%" }}
              />
              <p style={{ color: "#aaa" }}>
                {" "}
                {title["en-US"]}
                {typeof description !== "undefined"
                  ? ", " + description["en-US"]
                  : ""}{" "}
              </p>
            </div>
          </div>
        )
      },
    },
  }

  const name =
    data.allContentfulExhibition.nodes.length !== 0
      ? data.allContentfulExhibition.nodes[0].name
      : data.allContentfulProject.nodes.length !== 0
      ? data.allContentfulProject.nodes[0].name
      : "Utställning"

  const description = {
    data: {},
    nodeType: "document",
    content:
      data.allContentfulExhibition.nodes.length !== 0
        ? data.allContentfulExhibition.nodes[0].description.json.content
        : data.allContentfulProject.nodes.length !== 0
        ? data.allContentfulProject.nodes[0].description.json.content
        : [],
  }

  const descriptionText = {
    data: description.data,
    nodeType: description.nodeType,
    content: description.content.filter(item => {
      return item.nodeType !== "embedded-asset-block"
    }),
  }

  const descriptionImages = {
    data: description.data,
    nodeType: description.nodeType,
    content: description.content.filter(item => {
      return item.nodeType === "embedded-asset-block"
    }),
  }

  return (
    <Layout>
      <SEO title={name} keywords={[`Målning`, `Grafik`]} />
      {documentToReactComponents(descriptionImages, options)}
      <div className={"row"}>
        <div className={"eight columns offset-by-two"}>
          {documentToReactComponents(descriptionText, options)}
        </div>
      </div>
    </Layout>
  )
}
